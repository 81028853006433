// 海洋资源开发
export default [
    {
        id: "289",
        Num: "3",
        CompanyName: "埃克森美孚船舶润滑油有限公司",
        Link: "https://assets.vrnewg.com/289/html/index.html",
    },
    {
        id: "295",
        Num: "9",
        CompanyName: "北京海卓同创科技有限公司",
        Link: "https://assets.vrnewg.com/295/html/index.html",
    },
    {
        id: "297",
        Num: "2",
        CompanyName: "北京蓝创海洋科技有限公司",
        Link: "https://assets.vrnewg.com/297/html/index.html",
    },
    {
        id: "308",
        Num: "7",
        CompanyName: "俄罗斯石油股份公司北京代表处",
        Link: "https://assets.vrnewg.com/308/html/index.html",
    },
    {
        id: "310",
        Num: "7",
        CompanyName: "固蓝海事（重庆）工程技术有限公司",
        Link: "https://assets.vrnewg.com/310/html/index.html",
    },
    {
        id: "356",
        Num: "7",
        CompanyName: "上海晟江机械设备有限公司",
        Link: "https://assets.vrnewg.com/356/html/index.html",
    },
    {
        id: "358",
        Num: "7",
        CompanyName: "上海久存工业技术有限公司",
        Link: "https://assets.vrnewg.com/358/html/index.html",
    },
    {
        id: "363",
        Num: "7",
        CompanyName: "深圳贝壳红实业有限公司",
        Link: "https://assets.vrnewg.com/363/html/index.html",
    },
    {
        id: "365",
        Num: "7",
        CompanyName: "深圳赤湾胜宝旺工程有限公司",
        Link: "https://assets.vrnewg.com/365/html/index.html",
    },
    {
        id: "366",
        Num: "7",
        CompanyName: "深圳海力德油田技术开发有限公司",
        Link: "https://assets.vrnewg.com/366/html/index.html",
    },
    {
        id: "370",
        Num: "2",
        CompanyName: "深圳市艾依康仪器仪表科技有限公司",
        Link: "https://assets.vrnewg.com/370/html/index.html",
    },
    {
        id: "371",
        Num: "3",
        CompanyName: "深圳市佰特瑞新能源科技有限公司",
        Link: "https://assets.vrnewg.com/371/html/index.html",
    },
    {
        id: "374",
        Num: "1",
        CompanyName: "深圳市建安工程建设有限公司",
        Link: "https://assets.vrnewg.com/374/html/index.html",
    },
    {
        id: "380",
        Num: "1",
        CompanyName: "深圳新速通石油工具有限公司",
        Link: "https://assets.vrnewg.com/380/html/index.html",
    },
    {
        id: "384",
        Num: "3",
        CompanyName: "苏州华亘阀门有限公司",
        Link: "https://assets.vrnewg.com/384/html/index.html",
    },
    {
        id: "386",
        Num: "7",
        CompanyName: "维都利阀门有限公司",
        Link: "https://assets.vrnewg.com/386/html/index.html",
    },
    {
        id: "392",
        Num: "7",
        CompanyName: "营口宝唯管业发展有限公司",
        Link: "https://assets.vrnewg.com/392/html/index.html",
    },
    {
        id: "396",
        Num: "7",
        CompanyName: "张家港开密浦制泵有限公司",
        Link: "https://assets.vrnewg.com/396/html/index.html",
    },
    {
        id: "404",
        Num: "1",
        CompanyName: "中国科学院南海海洋研究所",
        Link: "https://assets.vrnewg.com/404/html/index.html",
    },
    {
        id: "405",
        Num: "10",
        CompanyName: "中海石油（中国）有限公司深圳分公司",
        Link: "https://assets.vrnewg.com/405/html/index.html",
    },
    {
        id: "408",
        Num: "3",
        CompanyName: "中科探海（苏州）海洋科技有限责任公司",
        Link: "https://assets.vrnewg.com/408/html/index.html",
    },
    {
        id: "431",
        Num: "3",
        CompanyName: "深圳志蓝技术有限公司",
        Link: "https://assets.vrnewg.com/431/html/index.html",
    },
    {
        id: "433",
        Num: "17",
        CompanyName: "上海中车艾森迪海洋装备有限公司",
        Link: "https://assets.vrnewg.com/433/html/index.html",
    },
    {
        id: "443",
        Num: "12",
        CompanyName: "深圳清华大学研究院",
        Link: "https://assets.vrnewg.com/443/html/index.html",
    },
    {
        id: "446",
        Num: "12",
        CompanyName: "赢胜节能",
        Link: "https://assets.vrnewg.com/446/html/index.html",
    },
    {
        id: "447",
        Num: "17",
        CompanyName: "北大研究生院",
        Link: "https://assets.vrnewg.com/447/html/index.html",
    },
    {
        id: "449",
        Num: "15",
        CompanyName: "深圳市麦斯韦博科技有限公司",
        Link: "https://assets.vrnewg.com/449/html/index.html",
    },
    {
        id: "460",
        Num: "10",
        CompanyName: "国家海洋技术中心",
        Link: "https://assets.vrnewg.com/460/html/index.html",
    },
    {
        id: "461",
        Num: "8",
        CompanyName: "艾克设备工程有限公司",
        Link: "https://assets.vrnewg.com/461/html/index.html",
    },
    {
        id: "462",
        Num: "4",
        CompanyName: "广东中威复合材料有限公司",
        Link: "https://assets.vrnewg.com/462/html/index.html",
    },
    {
        id: "463",
        Num: "3",
        CompanyName: "上海领海挪华企业发展有限公司",
        Link: "https://assets.vrnewg.com/463/html/index.html",
    },
    {
        id: "489",
        Num: "7",
        CompanyName: "杭州巨浪能源科技有限公司",
        Link: "https://assets.vrnewg.com/489/html/index.html",
    },
    {
        id: "506",
        Num: "7",
        CompanyName: "国家海洋信息中心",
        Link: "https://assets.vrnewg.com/506/html/index.html",
    },
    {
        id: "510",
        Num: "7",
        CompanyName: "上海义舟商务咨询有限公司",
        Link: "https://assets.vrnewg.com/510/html/index.html",
    },
    {
        id: "511",
        Num: "2",
        CompanyName: "深圳海洋融创研究院有限公司",
        Link: "https://assets.vrnewg.com/511/html/index.html",
    },
    {
        id: "517",
        Num: "7",
        CompanyName: "深圳市惠尔凯博海洋工程有限公司",
        Link: "https://assets.vrnewg.com/517/html/index.html",
    },
    {
        id: "533",
        Num: "7",
        CompanyName: "中远佐敦船舶涂料（青岛）有限公司",
        Link: "https://assets.vrnewg.com/533/html/index.html",
    },
    {
        id: "552",
        Num: "2",
        CompanyName: "百斯迈奇能源技术服务（深圳）有限公司",
        Link: "https://assets.vrnewg.com/552/html/index.html",
    },
    {
        id: "554",
        Num: "7",
        CompanyName: "北京大学深圳研究生院",
        Link: "https://assets.vrnewg.com/554/html/index.html",
    },
    {
        id: "555",
        Num: "7",
        CompanyName: "达索析统（上海）信息技术有限公司",
        Link: "https://assets.vrnewg.com/555/html/index.html",
    },
    {
        id: "558",
        Num: "2",
        CompanyName: "法国欧洲通信卫星公司北京代表处",
        Link: "https://assets.vrnewg.com/558/html/index.html",
    },
    {
        id: "561",
        Num: "7",
        CompanyName: "广州宝力特液压技术有限公司",
        Link: "https://assets.vrnewg.com/561/html/index.html",
    },
    {
        id: "563",
        Num: "7",
        CompanyName: "广州诺丹顿海洋科技有限公司",
        Link: "https://assets.vrnewg.com/563/html/index.html",
    },
    {
        id: "565",
        Num: "2",
        CompanyName: "广州星航船舶科技有限公司",
        Link: "https://assets.vrnewg.com/565/html/index.html",
    },
    {
        id: "567",
        Num: "7",
        CompanyName: "哈尔滨哈船减摇自动化设备有限公司",
        Link: "https://assets.vrnewg.com/567/html/index.html",
    },
    {
        id: "568",
        Num: "2",
        CompanyName: "哈尔滨哈船智控科技有限责任公司",
        Link: "https://assets.vrnewg.com/568/html/index.html",
    },
    {
        id: "569",
        Num: "7",
        CompanyName: "哈尔滨海探智造科技发展有限公司",
        Link: "https://assets.vrnewg.com/569/html/index.html",
    },
    {
        id: "571",
        Num: "2",
        CompanyName: "河北冠能石油机械制造有限公司",
        Link: "https://assets.vrnewg.com/571/html/index.html",
    },
    {
        id: "574",
        Num: "7",
        CompanyName: "加拿大先锋石油技术公司",
        Link: "https://assets.vrnewg.com/574/html/index.html",
    },
    {
        id: "575",
        Num: "2",
        CompanyName: "甲骨文（中国）软件系统有限公司",
        Link: "https://assets.vrnewg.com/575/html/index.html",
    },
    {
        id: "591",
        Num: "7",
        CompanyName: "深圳空循环科技有限公司",
        Link: "https://assets.vrnewg.com/591/html/index.html",
    },
    {
        id: "594",
        Num: "7",
        CompanyName: "深圳市哈尔滨工程大学校友会",
        Link: "https://assets.vrnewg.com/594/html/index.html",
    },
    {
        id: "603",
        Num: "2",
        CompanyName: "深圳忆海原识科技有限公司",
        Link: "https://assets.vrnewg.com/603/html/index.html",
    },
    {
        id: "604",
        Num: "7",
        CompanyName: "深圳亦芯智能视觉技术有限公司",
        Link: "https://assets.vrnewg.com/604/html/index.html",
    },
    {
        id: "607",
        Num: "7",
        CompanyName: "苏州钧舵机器人有限公司",
        Link: "https://assets.vrnewg.com/607/html/index.html",
    },
    {
        id: "608",
        Num: "2",
        CompanyName: "苏州睿牛机器人技术有限公司",
        Link: "https://assets.vrnewg.com/608/html/index.html",
    },
    {
        id: "624",
        Num: "7",
        CompanyName: "上海孚创动力电器有限公司",
        Link: "https://assets.vrnewg.com/624/html/index.html",
    },
    {
        id: "625",
        Num: "7",
        CompanyName: "云遥动力科技（苏州）有限公司",
        Link: "https://assets.vrnewg.com/625/html/index.html",
    },
    {
        id: "626",
        Num: "2",
        CompanyName: "广州先飞影视设备有限公司",
        Link: "https://assets.vrnewg.com/626/html/index.html",
    },
    {
        id: "225",
        Num: "1",
        CompanyName: "北京好利阀业集团有限公司",
        Link: "https://assets.vrnewg.com/225/html/index.html",
    },
    {
        id: "226",
        Num: "2",
        CompanyName: "河北联达过滤器材有限公司",
        Link: "https://assets.vrnewg.com/226/html/index.html",
    },
    {
        id: "227",
        Num: "2",
        CompanyName: "海默科技（集团）股份有限公司",
        Link: "https://assets.vrnewg.com/227/html/index.html",
    },
    {
        id: "228",
        Num: "1",
        CompanyName: "中天启明石油技术有限公司",
        Link: "https://assets.vrnewg.com/228/html/index.html",
    },
    {
        id: "449",
        Num: "15",
        CompanyName: "深圳市麦斯韦博科技有限公司",
        Link: "https://assets.vrnewg.com/449/html/index.html",
    },
    {
        id: "230",
        Num: "3",
        CompanyName: "深圳市优美环境治理有限公司",
        Link: "https://assets.vrnewg.com/230/html/index.html",
    },
    {
        id: "231",
        Num: "4",
        CompanyName: "烟台杰瑞石油装备技术有限公司",
        Link: "https://assets.vrnewg.com/231/html/index.html",
    },
    {
        id: "232",
        Num: "2",
        CompanyName: "广州司密达实业有限公司",
        Link: "https://assets.vrnewg.com/232/html/index.html",
    },
    {
        id: "233",
        Num: "1",
        CompanyName: "维特力（深圳）流体工程有限公司",
        Link: "https://assets.vrnewg.com/233/html/index.html",
    },
    {
        id: "234",
        Num: "2",
        CompanyName: "深圳市鲁明科通精密仪器股份有限公司",
        Link: "https://assets.vrnewg.com/234/html/index.html",
    },
    {
        id: "235",
        Num: "2",
        CompanyName: "海域海岛环境科技研究院（天津）有限公司",
        Link: "https://assets.vrnewg.com/235/html/index.html",
    },
    {
        id: "236",
        Num: "3",
        CompanyName: "宝鸡石油机械有限责任公司",
        Link: "https://assets.vrnewg.com/236/html/index.html",
    },
    {
        id: "237",
        Num: "3",
        CompanyName: "道达尔",
        Link: "https://assets.vrnewg.com/237/html/index.html",
    },
    {
        id: "238",
        Num: "1",
        CompanyName: "深圳南联股份有限公司",
        Link: "https://assets.vrnewg.com/238/html/index.html",
    },
    {
        id: "239",
        Num: "4",
        CompanyName: "中广核",
        Link: "https://assets.vrnewg.com/239/html/index.html",
    },
    {
        id: "700",
        Num: "4",
        CompanyName: "中国国际海运集装箱（集团）股份有限公司",
        Link: "https://assets.vrnewg.com/700/html/index.html",
    },
    {
        id: "171",
        Num: "4",
        CompanyName: "握友智能科技（深圳）有限公司",
        Link: "https://assets.vrnewg.com/171/html/index.html",
    },

    {
        id: 701,
        CompanyName: "宁波纽帕得机械有限公司",
        Link: "https://assets.vrnewg.com/701/html/index.html",
    },
    {
        id: 704,
        CompanyName: "深圳市康凯思特通讯设备有限公司",
        Link: "https://assets.vrnewg.com/704/html/index.html",
    },
    {
        id: 718,
        CompanyName: "中国美国商会中美能源合作项目",
        Link: "https://assets.vrnewg.com/718/html/index.html",
    },
    {
        id: 722,
        CompanyName: "深圳市正大流体机电设备有限公司",
        Link: "https://assets.vrnewg.com/722/html/index.html",
    },
    {
        id: 740,
        CompanyName: "硬核智造（深圳）科技有限公司",
        Link: "https://assets.vrnewg.com/740/html/index.html",
    },
    {
        id: 755,
        CompanyName: "顶坚（深圳）通讯技术有限公司",
        Link: "https://assets.vrnewg.com/755/html/index.html",
    },
    {
        id: 772,
        CompanyName: "国家深海基地管理中心",
        Link: "https://assets.vrnewg.com/772/html/index.html",
    },
    {
        id: 775,
        CompanyName: "长沙矿冶研究院有限责任公司",
        Link: "https://assets.vrnewg.com/775/html/index.html",
    },
    {
        id: 779,
        CompanyName: "北京氢峰低碳科技有限公司",
        Link: "https://assets.vrnewg.com/779/html/index.html",
    },
    {
        id: 787,
        CompanyName: "Altec阿尔泰克",
        Link: "https://assets.vrnewg.com/787/html/index.html",
    },
    {
        id: 788,
        CompanyName: "AspenTech",
        Link: "https://assets.vrnewg.com/788/html/index.html",
    },
    {
        id: 789,
        CompanyName: "Baker Hughes贝克休斯",
        Link: "https://assets.vrnewg.com/789/html/index.html",
    },
    {
        id: 790,
        CompanyName: "Cheniere切尼尔",
        Link: "https://assets.vrnewg.com/790/html/index.html",
    },
    {
        id: 791,
        CompanyName: "ConocoPhillips康菲石油",
        Link: "https://assets.vrnewg.com/791/html/index.html",
    },
    {
        id: 792,
        CompanyName: "伊顿电气Eaton",
        Link: "https://assets.vrnewg.com/792/html/index.html",
    },
    {
        id: 793,
        CompanyName: "艾默生Emerson",
        Link: "https://assets.vrnewg.com/793/html/index.html",
    },
    {
        id: 794,
        CompanyName: "埃克森美孚ExxonMobil",
        Link: "https://assets.vrnewg.com/794/html/index.html",
    },
    {
        id: 795,
        CompanyName: "Honeywell霍尼韦尔",
        Link: "https://assets.vrnewg.com/795/html/index.html",
    },
    {
        id: 796,
        CompanyName: "Westinghouse西屋电气",
        Link: "https://assets.vrnewg.com/796/html/index.html",
    },
    {
        id: 797,
        CompanyName: "Bureau Veritas必维集团",
        Link: "https://assets.vrnewg.com/797/html/index.html",
    },
    {
        id: 798,
        CompanyName: "Aramco Aisa阿美亚洲",
        Link: "https://assets.vrnewg.com/798/html/index.html",
    },
    {
        id: 799,
        CompanyName: "Cyberdyne Tech Exchange",
        Link: "https://assets.vrnewg.com/799/html/index.html",
    },
    {
        id: 800,
        CompanyName: "NORTON ROSE FULBRIGHT诺顿罗氏国际律师事务所",
        Link: "https://assets.vrnewg.com/800/html/index.html",
    },
    {
        id: 801,
        CompanyName: "易倍捷（上海）国际物流有限公司",
        Link: "https://assets.vrnewg.com/801/html/index.html",
    },
    {
        id: 802,
        CompanyName: "大连辉泓国际物流有限公司",
        Link: "https://assets.vrnewg.com/802/html/index.html",
    },
    {
        id: 803,
        CompanyName: "青岛俊鑫国际物流有限公司",
        Link: "https://assets.vrnewg.com/803/html/index.html",
    },
    {
        id: 804,
        CompanyName: "上海速恒国际物流有限公司",
        Link: "https://assets.vrnewg.com/804/html/index.html",
    },
    {
        id: 805,
        CompanyName: "天津福运国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/805/html/index.html",
    },
    {
        id: 806,
        CompanyName: "宝鸡市烨盛钛业有限公司",
        Link: "https://assets.vrnewg.com/806/html/index.html",
    },
    {
        id: 807,
        CompanyName: "宝鸡特钢钛业股份有限公司",
        Link: "https://assets.vrnewg.com/807/html/index.html",
    },
    {
        id: 808,
        CompanyName: "无锡银臻材料科技有限公司",
        Link: "https://assets.vrnewg.com/808/html/index.html",
    },
    {
        id: 809,
        CompanyName: "宝鸡市通润金属材料有限公司",
        Link: "https://assets.vrnewg.com/809/html/index.html",
    },
    {
        id: 810,
        CompanyName: "深圳海元国际物流股份有限公司",
        Link: "https://assets.vrnewg.com/810/html/index.html",
    },
    {
        id: 811,
        CompanyName: "中山新锐国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/811/html/index.html",
    },
    {
        id: 812,
        CompanyName: "均辉国际货运代理（上海）有限公司中山分公司",
        Link: "https://assets.vrnewg.com/812/html/index.html",
    },
    {
        id: 813,
        CompanyName: "深圳市华展国际物流有限公司",
        Link: "https://assets.vrnewg.com/813/html/index.html",
    },
    {
        id: 814,
        CompanyName: "深圳市万事航国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/814/html/index.html",
    },
    {
        id: 815,
        CompanyName: "广州市志通国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/815/html/index.html",
    },
    {
        id: 816,
        CompanyName: "广东瀛尊律师事务所-翟东卫物流律师团队",
        Link: "https://assets.vrnewg.com/816/html/index.html",
    },
    {
        id: 820,
        CompanyName: "广州建悦货运代理有限公司",
        Link: "https://assets.vrnewg.com/820/html/index.html",
    },
    {
        id: 821,
        CompanyName: "广州威林国际货运代理",
        Link: "https://assets.vrnewg.com/821/html/index.html",
    },
    {
        id: 822,
        CompanyName: "广东泽祥国际供应链管理有限公司广州分公司",
        Link: "https://assets.vrnewg.com/822/html/index.html",
    },
    {
        id: 823,
        CompanyName: "广东嘉盈国际供应链有限公司",
        Link: "https://assets.vrnewg.com/823/html/index.html",
    },
    {
        id: 824,
        CompanyName: "广州市昊链信息科技股份有限公司",
        Link: "https://assets.vrnewg.com/824/html/index.html",
    },
    {
        id: 825,
        CompanyName: "海德（广州）国际物流集团有限公司",
        Link: "https://assets.vrnewg.com/825/html/index.html",
    },
    {
        id: 826,
        CompanyName: "江苏晨泰物流有限公司广州分公司",
        Link: "https://assets.vrnewg.com/826/html/index.html",
    },
    {
        id: 827,
        CompanyName: "深圳海沃报关服务有限公司",
        Link: "https://assets.vrnewg.com/827/html/index.html",
    },
    {
        id: 828,
        CompanyName: "广州市春华物流有限公司",
        Link: "https://assets.vrnewg.com/828/html/index.html",
    },
    {
        id: 829,
        CompanyName: "广东鹏程国际船务代理有限公司",
        Link: "https://assets.vrnewg.com/829/html/index.html",
    },
    {
        id: 830,
        CompanyName: "广州市创嘉国际货运代理有限公司",
        Link: "https://assets.vrnewg.com/830/html/index.html",
    },
    {
        id: 843,
        CompanyName: "中机锻压江苏股份有限公司",
        Link: "https://assets.vrnewg.com/843/html/index.html",
    },
    {
        id: 854,
        CompanyName: "赢胜节能集团股份有限公司",
        Link: "https://assets.vrnewg.com/854/html/index.html",
    },
    {
        id: 857,
        CompanyName: "康士伯数字技术服务（北京）有限公司",
        Link: "https://assets.vrnewg.com/857/html/index.html",
    },
    {
        id: 861,
        CompanyName: "深圳市远东石油钻采工程有限公司",
        Link: "https://assets.vrnewg.com/861/html/index.html",
    },
    {
        id: 890,
        CompanyName: "安徽天康特种钢管有限公司",
        Link: "https://assets.vrnewg.com/890/html/index.html",
    },
];

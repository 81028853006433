// 海洋旅游与文化   2022/11/26  lwj新增
export default [
    {
        id: "599",
        Num: "2",
        CompanyName: "深圳市深地设计有限公司",
        Link: "https://assets.vrnewg.com/599/html/index.html",
    },
    {
        id: "136",
        Num: "2",
        CompanyName: "中国海事仲裁委员会华南分会",
        Link: "https://assets.vrnewg.com/136/html/index.html",
    },
    {
        id: "121",
        Num: "1",
        CompanyName: "《中国远洋海运报》社有限公司",
        Link: "https://assets.vrnewg.com/121/html/index.html",
    },
    {
        id: "500",
        Num: "7",
        CompanyName: "深圳市欧尼尔游艇有限公司",
        Link: "https://assets.vrnewg.com/500/html/index.html",
    },
    {
        id: "54",
        Num: "2",
        CompanyName: "深圳市休闲船艇协会",
        Link: "https://assets.vrnewg.com/54/html/index.html",
    },
    {
        id: "364",
        Num: "2",
        CompanyName: "深圳波通纳游艇码头工程有限公司",
        Link: "https://assets.vrnewg.com/364/html/index.html",
    },
    {
        id: "648",
        Num: "2",
        CompanyName: "深圳市飞驰游艇销售有限公司",
        Link: "https://assets.vrnewg.com/648/html/index.html",
    },
    {
        id: "661",
        Num: "2",
        CompanyName: "广东金桥百信律师事务所",
        Link: "https://assets.vrnewg.com/661/html/index.html",
    },
    {
        id: "669",
        Num: "2",
        CompanyName: "深圳深汕特别合作区岭南置业有限公司",
        Link: "https://assets.vrnewg.com/669/html/index.html",
    },
    {
        id: "672",
        Num: "2",
        CompanyName: "深圳市特发小梅沙投资发展有限公司",
        Link: "https://assets.vrnewg.com/672/html/index.html",
    },
    {
        id: "677",
        Num: "2",
        CompanyName: "珠海长隆投资发展有限公司",
        Link: "https://assets.vrnewg.com/677/html/index.html",
    },
    {
        id: "681",
        Num: "2",
        CompanyName: "东莞市小海龙科技有限公司",
        Link: "https://assets.vrnewg.com/681/html/index.html",
    },
    {
        id: "685",
        Num: "2",
        CompanyName: "交通银行股份有限公司深圳分行",
        Link: "https://assets.vrnewg.com/685/html/index.html",
    },
    {
        id: "686",
        Num: "2",
        CompanyName: "浪骑游艇会",
        Link: "https://assets.vrnewg.com/686/html/index.html",
    },
    {
        id: "689",
        Num: "2",
        CompanyName: "深圳市海之蓝游艇科技有限公司",
        Link: "https://assets.vrnewg.com/689/html/index.html",
    },
    {
        id: "697",
        Num: "2",
        CompanyName: "香港大昌行游艇",
        Link: "https://assets.vrnewg.com/697/html/index.html",
    },
];

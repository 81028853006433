// 电子信息
// 海洋电子信息
export default [
    {
        id: "1",
        Num: "4",
        CompanyName: "广东海启星海洋科技有限公司",
        Link: "https://assets.vrnewg.com/01/html/index.html",
    },
    {
        id: "2",
        Num: "2",
        CompanyName: "广东深汕投资控股集团有限公司",
        Link: "https://assets.vrnewg.com/02/html/index.html",
    },
    {
        id: "431",
        Num: "3",
        CompanyName: "深圳志蓝技术有限公司",
        Link: "https://assets.vrnewg.com/431/html/index.html",
    },
    {
        id: "4",
        Num: "1",
        CompanyName: "广东华风海洋信息系统服务有限公司",
        Link: "https://assets.vrnewg.com/04/html/index.html",
    },
    {
        id: "5",
        Num: "2",
        CompanyName: "深圳九洲海全通科技股份有限公司",
        Link: "https://assets.vrnewg.com/05/html/index.html",
    },
    {
        id: "6",
        Num: "4",
        CompanyName: "华讯方舟",
        Link: "https://assets.vrnewg.com/06/html/index.html",
    },
    {
        id: "7",
        Num: "2",
        CompanyName: "天津蓝鳍海洋工程有限公司",
        Link: "https://assets.vrnewg.com/07/html/index.html",
    },
    {
        id: "277",
        Num: "2",
        CompanyName: "深圳市数智海洋科技有限公司",
        Link: "https://assets.vrnewg.com/277/html/index.html",
    },
    {
        id: "126",
        Num: "1",
        CompanyName: "贵州钢绳股份有限公司",
        Link: "https://assets.vrnewg.com/126/html/index.html",
    },
    {
        id: "10",
        Num: "3",
        CompanyName: "华为海洋网络有限公司",
        Link: "https://assets.vrnewg.com/10/html/index.html",
    },
    {
        id: "11",
        Num: "1",
        CompanyName: "长沙金信诺防务技术有限公司",
        Link: "https://assets.vrnewg.com/11/html/index.html",
    },
    {
        id: "12",
        Num: "5",
        CompanyName: "青岛恒天翼信息科技有限公司",
        Link: "https://assets.vrnewg.com/12/html/index.html",
    },
    {
        id: "13",
        Num: "2",
        CompanyName: "深圳市星楷通讯设备有限公司",
        Link: "https://assets.vrnewg.com/13/html/index.html",
    },
    {
        id: "14",
        Num: "1",
        CompanyName: "遨海科技有限公司",
        Link: "https://assets.vrnewg.com/14/html/index.html",
    },
    {
        id: "15",
        Num: "2",
        CompanyName: "深圳申鲲科技有限公司",
        Link: "https://assets.vrnewg.com/15/html/index.html",
    },
    {
        id: "418",
        Num: "8",
        CompanyName: "广州磐钴智能科技有限公司",
        Link: "https://assets.vrnewg.com/418/html/index.html",
    },
    {
        id: "425",
        Num: "16",
        CompanyName: "青岛澎湃海洋探索技术有限公司",
        Link: "https://assets.vrnewg.com/425/html/index.html",
    },
    {
        id: "19",
        Num: "1",
        CompanyName: "深圳市立鑫瑞测控科技有限公司",
        Link: "https://assets.vrnewg.com/19/html/index.html",
    },
    {
        id: "261",
        Num: "1",
        CompanyName: "秦皇岛耀星航标科技有限公司",
        Link: "https://assets.vrnewg.com/261/html/index.html",
    },
    {
        id: "21",
        Num: "2",
        CompanyName: "青岛同力汇科技有限公司",
        Link: "https://assets.vrnewg.com/21/html/index.html",
    },
    {
        id: "22",
        Num: "1",
        CompanyName: "深圳微孚智能信息科技有限公司",
        Link: "https://assets.vrnewg.com/22/html/index.html",
    },
    {
        id: "23",
        Num: "1",
        CompanyName: "珠海市双捷科技有限公司",
        Link: "https://assets.vrnewg.com/23/html/index.html",
    },
    {
        id: "24",
        Num: "3",
        CompanyName: "山东未来机器人有限公司",
        Link: "https://assets.vrnewg.com/24/html/index.html",
    },
    {
        id: "25",
        Num: "2",
        CompanyName: "北京顶象技术有限公司",
        Link: "https://assets.vrnewg.com/25/html/index.html",
    },
    {
        id: "26",
        Num: "4",
        CompanyName: "广州易而达科技股份有限公司",
        Link: "https://assets.vrnewg.com/26/html/index.html",
    },
    {
        id: "27",
        Num: "2",
        CompanyName: "江苏星宇芯联电子科技有限公司",
        Link: "https://assets.vrnewg.com/27/html/index.html",
    },
    {
        id: "28",
        Num: "2",
        CompanyName: "深圳市清华视讯电子科技有限公司",
        Link: "https://assets.vrnewg.com/28/html/index.html",
    },
    {
        id: "29",
        Num: "3",
        CompanyName: "中天海洋系统有限公司",
        Link: "https://assets.vrnewg.com/29/html/index.html",
    },
    {
        id: "30",
        Num: "4",
        CompanyName: "宁波阿瑞斯自动化技术有限公司",
        Link: "https://assets.vrnewg.com/30/html/index.html",
    },
    {
        id: "33",
        Num: "1",
        CompanyName: "深圳市广社照明科技有限公司",
        Link: "https://assets.vrnewg.com/33/html/index.html",
    },
    {
        id: "34",
        Num: "3",
        CompanyName: "南方科技大学海洋科学与工程系",
        Link: "https://assets.vrnewg.com/34/html/index.html",
    },
    {
        id: "35",
        Num: "4",
        CompanyName: "杭州电子科技大学",
        Link: "https://assets.vrnewg.com/35/html/index.html",
    },
    {
        id: "263",
        Num: "2",
        CompanyName: "南京元厚电气有限公司",
        Link: "https://assets.vrnewg.com/263/html/index.html",
    },
    {
        id: "37",
        Num: "1",
        CompanyName: "深圳市中科海信科技有限公司",
        Link: "https://assets.vrnewg.com/37/html/index.html",
    },
    {
        id: "38",
        Num: "2",
        CompanyName: "汕尾市快捷通导设备有限公司",
        Link: "https://assets.vrnewg.com/38/html/index.html",
    },
    {
        id: "39",
        Num: "3",
        CompanyName: "上海海穆电子科技有限公司",
        Link: "https://assets.vrnewg.com/39/html/index.html",
    },
    {
        id: "40",
        Num: "5",
        CompanyName: "中电科（宁波）海洋电子研究院有限公司",
        Link: "https://assets.vrnewg.com/40/html/index.html",
    },
    {
        id: "264",
        Num: "7",
        CompanyName: "江苏中海达海洋信息技术有限公司",
        Link: "https://assets.vrnewg.com/264/html/index.html",
    },
    {
        id: "42",
        Num: "3",
        CompanyName: "江苏亨通海洋光网系统有限公司",
        Link: "https://assets.vrnewg.com/42/html/index.html",
    },
    {
        id: "43",
        Num: "4",
        CompanyName: "中国海洋大学",
        Link: "https://assets.vrnewg.com/43/html/index.html",
    },
    {
        id: "44",
        Num: "2",
        CompanyName: "北京和田星通科技开发有限公司",
        Link: "https://assets.vrnewg.com/44/html/index.html",
    },
    {
        id: "45",
        Num: "1",
        CompanyName: "北京航通天下科技有限公司",
        Link: "https://assets.vrnewg.com/45/html/index.html",
    },
    {
        id: "46",
        Num: "1",
        CompanyName: "深圳市维度统计咨询股份有限公司",
        Link: "https://assets.vrnewg.com/46/html/index.html",
    },
    {
        id: "47",
        Num: "2",
        CompanyName: "岭纬科技（厦门）有限公司",
        Link: "https://assets.vrnewg.com/47/html/index.html",
    },
    {
        id: "48",
        Num: "1",
        CompanyName: "鑫诺卫星通信有限公司",
        Link: "https://assets.vrnewg.com/48/html/index.html",
    },
    {
        id: "49",
        Num: "2",
        CompanyName: "深圳市新瑞科技有限公司",
        Link: "https://assets.vrnewg.com/49/html/index.html",
    },
    {
        id: "50",
        Num: "3",
        CompanyName: "广州至远海洋科技有限公司",
        Link: "https://assets.vrnewg.com/50/html/index.html",
    },
    {
        id: "51",
        Num: "1",
        CompanyName: "青岛世纪领航科技有限公司",
        Link: "https://assets.vrnewg.com/51/html/index.html",
    },
    {
        id: "52",
        Num: "1",
        CompanyName: "成都睿沿科技有限公司",
        Link: "https://assets.vrnewg.com/52/html/index.html",
    },
    {
        id: "53",
        Num: "2",
        CompanyName: "深圳佰鑫通科技有限公司",
        Link: "https://assets.vrnewg.com/53/html/index.html",
    },
    {
        id: "240",
        Num: "3",
        CompanyName: "中山市探海仪器有限公司",
        Link: "https://assets.vrnewg.com/240/html/index.html",
    },
    {
        id: "241",
        Num: "7",
        CompanyName: "北尔电子贸易（上海）有限公司",
        Link: "https://assets.vrnewg.com/241/html/index.html",
    },
    {
        id: "242",
        Num: "2",
        CompanyName: "行星轮（武汉）科技有限公司",
        Link: "https://assets.vrnewg.com/242/html/index.html",
    },
    {
        id: "243",
        Num: "7",
        CompanyName: "天津瀚海蓝帆海洋科技有限公司",
        Link: "https://assets.vrnewg.com/243/html/index.html",
    },
    {
        id: "244",
        Num: "2",
        CompanyName: "深圳市亿威仕流体控制有限公司",
        Link: "https://assets.vrnewg.com/244/html/index.html",
    },
    {
        id: "245",
        Num: "6",
        CompanyName: "深圳市置辰海信科技有限公司",
        Link: "https://assets.vrnewg.com/245/html/index.html",
    },
    {
        id: "246",
        Num: "6",
        CompanyName: "舟山美通信息技术有限责任公司",
        Link: "https://assets.vrnewg.com/246/html/index.html",
    },
    {
        id: "247",
        Num: "2",
        CompanyName: "上海海奕环境科技有限公司",
        Link: "https://assets.vrnewg.com/247/html/index.html",
    },
    {
        id: "248",
        Num: "7",
        CompanyName: "杭州谱海科技有限公司",
        Link: "https://assets.vrnewg.com/248/html/index.html",
    },
    {
        id: "249",
        Num: "1",
        CompanyName: "南通鲲腾光电有限公司",
        Link: "https://assets.vrnewg.com/249/html/index.html",
    },
    {
        id: "250",
        Num: "3",
        CompanyName: "上海丙寅电子有限公司",
        Link: "https://assets.vrnewg.com/250/html/index.html",
    },
    {
        id: "251",
        Num: "7",
        CompanyName: "青岛海舟科技有限公司",
        Link: "https://assets.vrnewg.com/251/html/index.html",
    },
    {
        id: "252",
        Num: "2",
        CompanyName: "广州浩瀚电子科技有限公司",
        Link: "https://assets.vrnewg.com/252/html/index.html",
    },
    {
        id: "253",
        Num: "1",
        CompanyName: "广州恒威电子科技有限公司",
        Link: "https://assets.vrnewg.com/253/html/index.html",
    },
    {
        id: "254",
        Num: "4",
        CompanyName: "深圳市华普星通技术有限公司",
        Link: "https://assets.vrnewg.com/254/html/index.html",
    },
    {
        id: "255",
        Num: "2",
        CompanyName: "武汉劳雷绿湾船舶科技有限公司",
        Link: "https://assets.vrnewg.com/255/html/index.html",
    },
    {
        id: "256",
        Num: "6",
        CompanyName: "南京方之舆科技有限公司",
        Link: "https://assets.vrnewg.com/256/html/index.html",
    },
    {
        id: "257",
        Num: "7",
        CompanyName: "宁波博海深衡科技有限公司",
        Link: "https://assets.vrnewg.com/257/html/index.html",
    },
    {
        id: "259",
        Num: "1",
        CompanyName: "青岛瑞泰尔电子技术有限公司",
        Link: "https://assets.vrnewg.com/259/html/index.html",
    },
    {
        id: "261",
        Num: "1",
        CompanyName: "秦皇岛耀星航标科技有限公司",
        Link: "https://assets.vrnewg.com/261/html/index.html",
    },
    {
        id: "262",
        Num: "1",
        CompanyName: "泰兴市宇航电子有限公司",
        Link: "https://assets.vrnewg.com/262/html/index.html",
    },
    {
        id: "263",
        Num: "2",
        CompanyName: "南京元厚电气有限公司",
        Link: "https://assets.vrnewg.com/263/html/index.html",
    },
    {
        id: "264",
        Num: "7",
        CompanyName: "江苏中海达海洋信息技术有限公司",
        Link: "https://assets.vrnewg.com/264/html/index.html",
    },
    {
        id: "266",
        Num: "7",
        CompanyName: "深圳市斯威普科技有限公司",
        Link: "https://assets.vrnewg.com/266/html/index.html",
    },
    {
        id: "267",
        Num: "1",
        CompanyName: "天津昊野科技有限公司",
        Link: "https://assets.vrnewg.com/267/html/index.html",
    },
    {
        id: "268",
        Num: "7",
        CompanyName: "安徽科微智能科技有限公司",
        Link: "https://assets.vrnewg.com/268/html/index.html",
    },
    {
        id: "270",
        Num: "7",
        CompanyName: "珠海市蓝衡科技有限公司",
        Link: "https://assets.vrnewg.com/270/html/index.html",
    },
    {
        id: "271",
        Num: "10",
        CompanyName: "厦门北斗通信息技术股份有限公司",
        Link: "https://assets.vrnewg.com/271/html/index.html",
    },
    {
        id: "272",
        Num: "9",
        CompanyName: "青岛森科特智能仪器有限公司",
        Link: "https://assets.vrnewg.com/272/html/index.html",
    },
    {
        id: "273",
        Num: "2",
        CompanyName: "上海精导科学仪器有限公司",
        Link: "https://assets.vrnewg.com/273/html/index.html",
    },
    {
        id: "274",
        Num: "2",
        CompanyName: "南方科技大学",
        Link: "https://assets.vrnewg.com/274/html/index.html",
    },
    {
        id: "275",
        Num: "6",
        CompanyName: "上海瑞领电缆有限公司",
        Link: "https://assets.vrnewg.com/275/html/index.html",
    },
    {
        id: "276",
        Num: "7",
        CompanyName: "北京神州普惠科技股份有限公司",
        Link: "https://assets.vrnewg.com/276/html/index.html",
    },
    {
        id: "277",
        Num: "2",
        CompanyName: "深圳市数智海洋科技有限公司",
        Link: "https://assets.vrnewg.com/277/html/index.html",
    },
    {
        id: "278",
        Num: "7",
        CompanyName: "海域海岛环境科技研究院（天津)有限公司",
        Link: "https://assets.vrnewg.com/278/html/index.html",
    },
    {
        id: "279",
        Num: "1",
        CompanyName: "深圳中检联检测有限公司",
        Link: "https://assets.vrnewg.com/279/html/index.html",
    },
    {
        id: "280",
        Num: "8",
        CompanyName: "广东海洋大学",
        Link: "https://assets.vrnewg.com/280/html/index.html",
    },
    {
        id: "281",
        Num: "7",
        CompanyName: "广州海友电子科技有限公司",
        Link: "https://assets.vrnewg.com/281/html/index.html",
    },
    {
        id: "282",
        Num: "3",
        CompanyName: "浙江氢航科技有限公司",
        Link: "https://assets.vrnewg.com/282/html/index.html",
    },
    {
        id: "283",
        Num: "7",
        CompanyName: "深圳市信为科技发展有限公司",
        Link: "https://assets.vrnewg.com/283/html/index.html",
    },
    {
        id: "284",
        Num: "7",
        CompanyName: "江苏乐众信息技术股份有限公司",
        Link: "https://assets.vrnewg.com/284/html/index.html",
    },
    {
        id: "285",
        Num: "7",
        CompanyName: "北京中航天佑科技有限公司",
        Link: "https://assets.vrnewg.com/285/html/index.html",
    },
    {
        id: "286",
        Num: "1",
        CompanyName: "北京维盛檀越科技集团有限公司",
        Link: "https://assets.vrnewg.com/286/html/index.html",
    },
    {
        id: "287",
        Num: "8",
        CompanyName: "厦门蓝海天信息技术有限公司",
        Link: "https://assets.vrnewg.com/287/html/index.html",
    },
    {
        id: "288",
        Num: "7",
        CompanyName: "上海溪淇电子科技有限公司",
        Link: "https://assets.vrnewg.com/288/html/index.html",
    },
    {
        id: "291",
        Num: "7",
        CompanyName: "百夫长传感技术（深圳）有限公司",
        Link: "https://assets.vrnewg.com/291/html/index.html",
    },
    {
        id: "293",
        Num: "7",
        CompanyName: "北京大洋经略科技有限公司",
        Link: "https://assets.vrnewg.com/293/html/index.html",
    },
    {
        id: "453",
        Num: "16",
        CompanyName: "北京海兰信数据科技股份有限公司",
        Link: "https://assets.vrnewg.com/453/html/index.html",
    },
    {
        id: "298",
        Num: "1",
        CompanyName: "北京六合伟业科技股份有限公司",
        Link: "https://assets.vrnewg.com/298/html/index.html",
    },
    {
        id: "302",
        Num: "7",
        CompanyName: "德微电技术（深圳）有限公司",
        Link: "https://assets.vrnewg.com/302/html/index.html",
    },
    {
        id: "313",
        Num: "8",
        CompanyName: "广州高奇海洋科技有限公司",
        Link: "https://assets.vrnewg.com/313/html/index.html",
    },
    {
        id: "318",
        Num: "7",
        CompanyName: "广州宇航智能科技有限公司",
        Link: "https://assets.vrnewg.com/318/html/index.html",
    },
    {
        id: "428",
        Num: "16",
        CompanyName: "杭州浅海科技有限责任公司",
        Link: "https://assets.vrnewg.com/428/html/index.html",
    },
    {
        id: "336",
        Num: "3",
        CompanyName: "穆特科技（武汉）股份有限公司",
        Link: "https://assets.vrnewg.com/336/html/index.html",
    },
    {
        id: "423",
        Num: "17",
        CompanyName: "青岛策海自动化科技有限公司",
        Link: "https://assets.vrnewg.com/423/html/index.html",
    },
    {
        id: "342",
        Num: "7",
        CompanyName: "青岛道万科技有限公司",
        Link: "https://assets.vrnewg.com/342/html/index.html",
    },
    {
        id: "346",
        Num: "2",
        CompanyName: "青岛南森海洋科技有限公司",
        Link: "https://assets.vrnewg.com/346/html/index.html",
    },
    {
        id: "350",
        Num: "9",
        CompanyName: "赛尼尔(上海)系统有限公司",
        Link: "https://assets.vrnewg.com/350/html/index.html",
    },
    {
        id: "352",
        Num: "7",
        CompanyName: "山东鲁普科技有限公司",
        Link: "https://assets.vrnewg.com/352/html/index.html",
    },
    {
        id: "357",
        Num: "7",
        CompanyName: "上海华襄机械有限公司",
        Link: "https://assets.vrnewg.com/357/html/index.html",
    },
    {
        id: "359",
        Num: "7",
        CompanyName: "上海蓝梭电子科技有限公司",
        Link: "https://assets.vrnewg.com/359/html/index.html",
    },
    {
        id: "369",
        Num: "7",
        CompanyName: "深圳酷莱瑞科技发展有限公司",
        Link: "https://assets.vrnewg.com/369/html/index.html",
    },
    {
        id: "372",
        Num: "1",
        CompanyName: "深圳市华至云端技术有限公司",
        Link: "https://assets.vrnewg.com/372/html/index.html",
    },
    {
        id: "373",
        Num: "9",
        CompanyName: "深圳市吉影科技有限公司",
        Link: "https://assets.vrnewg.com/373/html/index.html",
    },
    {
        id: "376",
        Num: "3",
        CompanyName: "深圳市朗诚科技股份有限公司",
        Link: "https://assets.vrnewg.com/376/html/index.html",
    },
    {
        id: "388",
        Num: "9",
        CompanyName: "武汉蓝星海洋电子有限公司",
        Link: "https://assets.vrnewg.com/388/html/index.html",
    },
    {
        id: "393",
        Num: "7",
        CompanyName: "永高股份有限公司",
        Link: "https://assets.vrnewg.com/393/html/index.html",
    },
    {
        id: "394",
        Num: "7",
        CompanyName: "约肯机器人（上海）有限公司",
        Link: "https://assets.vrnewg.com/394/html/index.html",
    },
    {
        id: "401",
        Num: "3",
        CompanyName: "浙江易航海信息技术有限公司",
        Link: "https://assets.vrnewg.com/401/html/index.html",
    },
    {
        id: "403",
        Num: "2",
        CompanyName: "镇江新益舟密封制品有限公司",
        Link: "https://assets.vrnewg.com/403/html/index.html",
    },
    {
        id: "407",
        Num: "7",
        CompanyName: "中环宇恩（广东）生态科技有限公司",
        Link: "https://assets.vrnewg.com/407/html/index.html",
    },
    {
        id: "410",
        Num: "7",
        CompanyName: "中信海洋（舟山）卫星通信有限公司",
        Link: "https://assets.vrnewg.com/410/html/index.html",
    },
    {
        id: "457",
        Num: "13",
        CompanyName: "中兴通讯股份有限公司",
        Link: "https://assets.vrnewg.com/457/html/index.html",
    },
    {
        id: "416",
        Num: "9",
        CompanyName: "自然资源部第一海洋研究所",
        Link: "https://assets.vrnewg.com/416/html/index.html",
    },
    {
        id: "417",
        Num: "12",
        CompanyName: "深圳市海伊石油技术有限公司",
        Link: "https://assets.vrnewg.com/417/html/index.html",
    },
    {
        id: "418",
        Num: "8",
        CompanyName: "广州磐钴智能科技有限公司",
        Link: "https://assets.vrnewg.com/418/html/index.html",
    },
    {
        id: "419",
        Num: "3",
        CompanyName: "安徽欣思创科技有限公司",
        Link: "https://assets.vrnewg.com/419/html/index.html",
    },
    {
        id: "420",
        Num: "12",
        CompanyName: "深圳市富创优越科技有限公司",
        Link: "https://assets.vrnewg.com/420/html/index.html",
    },
    {
        id: "421",
        Num: "8",
        CompanyName: "山东省科学院海洋仪器仪表研究所",
        Link: "https://assets.vrnewg.com/421/html/index.html",
    },
    {
        id: "422",
        Num: "9",
        CompanyName: "深圳市腾远智拓电子有限公司",
        Link: "https://assets.vrnewg.com/422/html/index.html",
    },
    {
        id: "423",
        Num: "17",
        CompanyName: "青岛策海自动化科技有限公司",
        Link: "https://assets.vrnewg.com/423/html/index.html",
    },
    {
        id: "424",
        Num: "17",
        CompanyName: "珠海漫漫科技有限公司",
        Link: "https://assets.vrnewg.com/424/html/index.html",
    },
    {
        id: "425",
        Num: "16",
        CompanyName: "青岛澎湃海洋探索技术有限公司",
        Link: "https://assets.vrnewg.com/425/html/index.html",
    },
    {
        id: "426",
        Num: "14",
        CompanyName: "舟山海慧海洋科技有限公司",
        Link: "https://assets.vrnewg.com/426/html/index.html",
    },
    {
        id: "427",
        Num: "12",
        CompanyName: "广州睿海海洋科技有限公司",
        Link: "https://assets.vrnewg.com/427/html/index.html",
    },
    {
        id: "428",
        Num: "16",
        CompanyName: "杭州浅海科技有限责任公司",
        Link: "https://assets.vrnewg.com/428/html/index.html",
    },
    {
        id: "429",
        Num: "8",
        CompanyName: "深圳市泽科润滑科技有限公司",
        Link: "https://assets.vrnewg.com/429/html/index.html",
    },
    {
        id: "434",
        Num: "13",
        CompanyName: "广东逸动科技有限公司",
        Link: "https://assets.vrnewg.com/434/html/index.html",
    },
    {
        id: "448",
        Num: "3",
        CompanyName: "天海世界卫星",
        Link: "https://assets.vrnewg.com/448/html/index.html",
    },
    {
        id: "453",
        Num: "16",
        CompanyName: "北京海兰信数据科技股份有限公司",
        Link: "https://assets.vrnewg.com/453/html/index.html",
    },
    {
        id: "454",
        Num: "9",
        CompanyName: "欧洲通信卫星公司",
        Link: "https://assets.vrnewg.com/454/html/index.html",
    },
    {
        id: "457",
        Num: "13",
        CompanyName: "中兴通讯股份有限公司",
        Link: "https://assets.vrnewg.com/457/html/index.html",
    },
    {
        id: "458",
        Num: "12",
        CompanyName: "北京神州惠普科技股份有限公司",
        Link: "https://assets.vrnewg.com/458/html/index.html",
    },
    {
        id: "478",
        Num: "7",
        CompanyName: "北京光华世通科技有限公司",
        Link: "https://assets.vrnewg.com/478/html/index.html",
    },
    {
        id: "481",
        Num: "7",
        CompanyName: "常州市睿速电气科技有限公司",
        Link: "https://assets.vrnewg.com/481/html/index.html",
    },
    {
        id: "482",
        Num: "2",
        CompanyName: "东莞天太无人机技术有限公司",
        Link: "https://assets.vrnewg.com/482/html/index.html",
    },
    {
        id: "483",
        Num: "2",
        CompanyName: "广州广电计量检测股份有限公司",
        Link: "https://assets.vrnewg.com/483/html/index.html",
    },
    {
        id: "485",
        Num: "7",
        CompanyName: "海南省海洋与渔业科学院",
        Link: "https://assets.vrnewg.com/485/html/index.html",
    },
    {
        id: "487",
        Num: "7",
        CompanyName: "杭州鳌海海洋工程技术有限公司",
        Link: "https://assets.vrnewg.com/487/html/index.html",
    },
    {
        id: "490",
        Num: "3",
        CompanyName: "豪氏威马（中国）有限公司",
        Link: "https://assets.vrnewg.com/490/html/index.html",
    },
    {
        id: "491",
        Num: "7",
        CompanyName: "江苏海洋大学",
        Link: "https://assets.vrnewg.com/491/html/index.html",
    },
    {
        id: "495",
        Num: "7",
        CompanyName: "南京华研动密封科技有限公司",
        Link: "https://assets.vrnewg.com/495/html/index.html",
    },
    {
        id: "499",
        Num: "7",
        CompanyName: "深圳市欧艾智能技术有限公司",
        Link: "https://assets.vrnewg.com/499/html/index.html",
    },
    {
        id: "501",
        Num: "6",
        CompanyName: "深圳市维力谷无线技术股份有限公司",
        Link: "https://assets.vrnewg.com/501/html/index.html",
    },
    {
        id: "503",
        Num: "2",
        CompanyName: "谐通船舶海洋工程技术（上海）有限公司",
        Link: "https://assets.vrnewg.com/503/html/index.html",
    },
    {
        id: "508",
        Num: "2",
        CompanyName: "上海盛怡压缩机有限公司",
        Link: "https://assets.vrnewg.com/508/html/index.html",
    },
    {
        id: "513",
        Num: "2",
        CompanyName: "深圳鳍源科技有限公司",
        Link: "https://assets.vrnewg.com/513/html/index.html",
    },
    {
        id: "514",
        Num: "7",
        CompanyName: "深圳市百勤石油技术有限公司",
        Link: "https://assets.vrnewg.com/514/html/index.html",
    },
    {
        id: "516",
        Num: "7",
        CompanyName: "深圳市海洋石油服务企业协会",
        Link: "https://assets.vrnewg.com/516/html/index.html",
    },
    {
        id: "518",
        Num: "2",
        CompanyName: "深圳市金航深海矿产开发集团有限公司",
        Link: "https://assets.vrnewg.com/518/html/index.html",
    },
    {
        id: "523",
        Num: "7",
        CompanyName: "深圳市卫星物联网产业协会",
        Link: "https://assets.vrnewg.com/523/html/index.html",
    },
    {
        id: "524",
        Num: "7",
        CompanyName: "深圳市闻迅数码科技有限公司",
        Link: "https://assets.vrnewg.com/524/html/index.html",
    },
    {
        id: "525",
        Num: "7",
        CompanyName: "深圳市智慧海洋科技有限公司",
        Link: "https://assets.vrnewg.com/525/html/index.html",
    },
    {
        id: "526",
        Num: "7",
        CompanyName: "深圳中喆海洋科技有限公司",
        Link: "https://assets.vrnewg.com/526/html/index.html",
    },
    {
        id: "528",
        Num: "2",
        CompanyName: "西安天和海防智能科技有限公司",
        Link: "https://assets.vrnewg.com/528/html/index.html",
    },
    {
        id: "530",
        Num: "2",
        CompanyName: "镇江申庭电子科技有限公司",
        Link: "https://assets.vrnewg.com/530/html/index.html",
    },
    {
        id: "532",
        Num: "7",
        CompanyName: "中冶建筑研究总院有限公司",
        Link: "https://assets.vrnewg.com/532/html/index.html",
    },
    {
        id: "534",
        Num: "7",
        CompanyName: "珠海天岳科技股份有限公司",
        Link: "https://assets.vrnewg.com/534/html/index.html",
    },
    {
        id: "536",
        Num: "7",
        CompanyName: "CEPro能源集团公司",
        Link: "https://assets.vrnewg.com/536/html/index.html",
    },
    {
        id: "537",
        Num: "7",
        CompanyName: "Extreme Spill Technology",
        Link: "https://assets.vrnewg.com/537/html/index.html",
    },
    {
        id: "538",
        Num: "2",
        CompanyName: "Grizzly 公司",
        Link: "https://assets.vrnewg.com/538/html/index.html",
    },
    {
        id: "539",
        Num: "7",
        CompanyName: "JORI国际有限公司",
        Link: "https://assets.vrnewg.com/539/html/index.html",
    },
    {
        id: "540",
        Num: "7",
        CompanyName: "National Compressed Air Canada Ltd",
        Link: "https://assets.vrnewg.com/540/html/index.html",
    },
    {
        id: "541",
        Num: "2",
        CompanyName: "New Star 流量控制公司",
        Link: "https://assets.vrnewg.com/541/html/index.html",
    },
    {
        id: "542",
        Num: "7",
        CompanyName: "Ozzie’s Pipeline Pad公司",
        Link: "https://assets.vrnewg.com/542/html/index.html",
    },
    {
        id: "543",
        Num: "2",
        CompanyName: "ReWatt Power公司",
        Link: "https://assets.vrnewg.com/543/html/index.html",
    },
    {
        id: "544",
        Num: "7",
        CompanyName: "RGL Reservoir Management 公司",
        Link: "https://assets.vrnewg.com/544/html/index.html",
    },
    {
        id: "545",
        Num: "2",
        CompanyName: "Solstice加拿大公司",
        Link: "https://assets.vrnewg.com/545/html/index.html",
    },
    {
        id: "546",
        Num: "7",
        CompanyName: "Stream Flo 实业有限公司",
        Link: "https://assets.vrnewg.com/546/html/index.html",
    },
    {
        id: "547",
        Num: "7",
        CompanyName: "Terralog Technologies 公司",
        Link: "https://assets.vrnewg.com/547/html/index.html",
    },
    {
        id: "548",
        Num: "2",
        CompanyName: "TKS工业公司",
        Link: "https://assets.vrnewg.com/548/html/index.html",
    },
    {
        id: "549",
        Num: "7",
        CompanyName: "U-Target Energy 公司",
        Link: "https://assets.vrnewg.com/549/html/index.html",
    },
    {
        id: "550",
        Num: "2",
        CompanyName: "Vertex Downhole公司",
        Link: "https://assets.vrnewg.com/550/html/index.html",
    },
    {
        id: "560",
        Num: "2",
        CompanyName: "广州邦讯信息系统有限公司",
        Link: "https://assets.vrnewg.com/560/html/index.html",
    },
    {
        id: "562",
        Num: "7",
        CompanyName: "广州南方海洋科技有限公司",
        Link: "https://assets.vrnewg.com/562/html/index.html",
    },
    {
        id: "566",
        Num: "7",
        CompanyName: "国家海洋标准计量中心",
        Link: "https://assets.vrnewg.com/566/html/index.html",
    },
    {
        id: "573",
        Num: "2",
        CompanyName: "加拿大玛特芙阀门有限公司",
        Link: "https://assets.vrnewg.com/573/html/index.html",
    },
    {
        id: "576",
        Num: "7",
        CompanyName: "江苏茂生特种电缆有限公司",
        Link: "https://assets.vrnewg.com/576/html/index.html",
    },
    {
        id: "583",
        Num: "7",
        CompanyName: "宁波瑞德森船用泵制造有限公司",
        Link: "https://assets.vrnewg.com/583/html/index.html",
    },
    {
        id: "585",
        Num: "7",
        CompanyName: "厦门大学海洋与地球学院",
        Link: "https://assets.vrnewg.com/585/html/index.html",
    },
    {
        id: "586",
        Num: "7",
        CompanyName: "上海传输线研究所（中国电子科技集团公司第二十三研究所）",
        Link: "https://assets.vrnewg.com/586/html/index.html",
    },
    {
        id: "602",
        Num: "7",
        CompanyName: "深圳市泽科润滑科技有限公司（壳牌深圳，东莞区域代理）",
        Link: "https://assets.vrnewg.com/602/html/index.html",
    },
    {
        id: "605",
        Num: "7",
        CompanyName: "深之蓝海洋科技股份有限公司",
        Link: "https://assets.vrnewg.com/605/html/index.html",
    },
    {
        id: "609",
        Num: "7",
        CompanyName: "拓纳多特车工程技术有限公司",
        Link: "https://assets.vrnewg.com/609/html/index.html",
    },
    {
        id: "614",
        Num: "2",
        CompanyName: "芜湖阿尔发飞行器有限公司",
        Link: "https://assets.vrnewg.com/614/html/index.html",
    },
    {
        id: "627",
        Num: "2",
        CompanyName: "金朗科技（深圳）有限责任公司",
        Link: "https://assets.vrnewg.com/627/html/index.html",
    },
    {
        id: "414",
        Num: "2",
        CompanyName: "珠海中科先进技术研究院有限公司",
        Link: "https://assets.vrnewg.com/414/html/index.html",
    },
    {
        id: "642",
        Num: "7",
        CompanyName: "深圳面元智能科技有限公司",
        Link: "https://assets.vrnewg.com/642/html/index.html",
    },
    {
        id: "642",
        Num: "7",
        CompanyName: "深圳面元智能科技有限公司",
        Link: "https://assets.vrnewg.com/642/html/index.html",
    },
    {
        id: "643",
        Num: "7",
        CompanyName: "北京北斗时代科技发展有限公司",
        Link: "https://assets.vrnewg.com/643/html/index.html",
    },
    {
        id: "645",
        Num: "7",
        CompanyName: "成都帝欣科技有限公司",
        Link: "https://assets.vrnewg.com/645/html/index.html",
    },
    {
        id: "647",
        Num: "7",
        CompanyName: "廊坊市海洋升帆科技有限公司",
        Link: "https://assets.vrnewg.com/647/html/index.html",
    },
    {
        id: "654",
        Num: "7",
        CompanyName: "沈阳星之火软件有限公司",
        Link: "https://assets.vrnewg.com/654/html/index.html",
    },
    {
        id: "655",
        Num: "7",
        CompanyName: "惟度(武汉)科技有限公司",
        Link: "https://assets.vrnewg.com/655/html/index.html",
    },
    {
        id: "658",
        Num: "7",
        CompanyName: "中科探海（深圳）海洋科技有限责任公司",
        Link: "https://assets.vrnewg.com/658/html/index.html",
    },
    {
        id: "665",
        Num: "7",
        CompanyName: "宁波同盛海洋科技有限公司",
        Link: "https://assets.vrnewg.com/665/html/index.html",
    },
    {
        id: "667",
        Num: "7",
        CompanyName: "深圳安易控动力科技有限公司",
        Link: "https://assets.vrnewg.com/667/html/index.html",
    },
    {
        id: "670",
        Num: "7",
        CompanyName: "深圳市达沃电子有限公司",
        Link: "https://assets.vrnewg.com/670/html/index.html",
    },
    {
        id: "671",
        Num: "7",
        CompanyName: "深圳市深国安电子科技有限公司",
        Link: "https://assets.vrnewg.com/671/html/index.html",
    },
    {
        id: "674",
        Num: "7",
        CompanyName: "沈阳元邦科技有限公司",
        Link: "https://assets.vrnewg.com/674/html/index.html",
    },
    {
        id: "675",
        Num: "7",
        CompanyName: "中国无人系统产业联盟",
        Link: "https://assets.vrnewg.com/675/html/index.html",
    },
    {
        id: "679",
        Num: "7",
        CompanyName: "北京维周智能科技有限公司",
        Link: "https://assets.vrnewg.com/679/html/index.html",
    },
    {
        id: "680",
        Num: "7",
        CompanyName: "碧兴物联科技（深圳）股份有限公司",
        Link: "https://assets.vrnewg.com/680/html/index.html",
    },
    {
        id: "688",
        Num: "7",
        CompanyName: "南昌临空通讯科技有限公司",
        Link: "https://assets.vrnewg.com/688/html/index.html",
    },
    {
        id: "690",
        Num: "7",
        CompanyName: "深圳市精敏数字机器有限公司",
        Link: "https://assets.vrnewg.com/690/html/index.html",
    },
    {
        id: "691",
        Num: "7",
        CompanyName: "深圳市镭神智能系统有限公司",
        Link: "https://assets.vrnewg.com/691/html/index.html",
    },
    {
        id: "694",
        Num: "7",
        CompanyName: "深圳市云天智能通讯有限公司",
        Link: "https://assets.vrnewg.com/694/html/index.html",
    },
    {
        id: "695",
        Num: "7",
        CompanyName: "钛业资讯理事会",
        Link: "https://assets.vrnewg.com/695/html/index.html",
    },
    {
        id: "696",
        Num: "7",
        CompanyName: "西安智荣机电科技有限公司",
        Link: "https://assets.vrnewg.com/696/html/index.html",
    },
    {
        id: "698",
        Num: "7",
        CompanyName: "鹰图软件技术（青岛）有限公司",
        Link: "https://assets.vrnewg.com/698/html/index.html",
    },

    {
        id: 707,
        CompanyName: "安阳潜航海洋装备制造有限公司",
        Link: "https://assets.vrnewg.com/707/html/index.html",
    },
    {
        id: 708,
        CompanyName: "深圳星联天通科技有限公司",
        Link: "https://assets.vrnewg.com/708/html/index.html",
    },
    {
        id: 709,
        CompanyName: "深圳华力创通科技有限公司",
        Link: "https://assets.vrnewg.com/709/html/index.html",
    },
    {
        id: 710,
        CompanyName: "山东星冉信息科技有限公司",
        Link: "https://assets.vrnewg.com/710/html/index.html",
    },
    {
        id: 711,
        CompanyName: "深圳思凯微电子有限公司",
        Link: "https://assets.vrnewg.com/711/html/index.html",
    },
    {
        id: 712,
        CompanyName: "深圳市华信天线技术有限公司",
        Link: "https://assets.vrnewg.com/712/html/index.html",
    },
    {
        id: 715,
        CompanyName: "北京赛迪海洋技术中心",
        Link: "https://assets.vrnewg.com/715/html/index.html",
    },
    {
        id: 716,
        CompanyName: "上海宜通海洋科技股份有限公司",
        Link: "https://assets.vrnewg.com/716/html/index.html",
    },
    {
        id: 719,
        CompanyName: "深圳市网商天下科技开发有限公司",
        Link: "https://assets.vrnewg.com/719/html/index.html",
    },
    {
        id: 720,
        CompanyName: "浙江元通线缆制造有限公司",
        Link: "https://assets.vrnewg.com/720/html/index.html",
    },
    {
        id: 723,
        CompanyName: "深圳市天海世界卫星应用科技有限公司",
        Link: "https://assets.vrnewg.com/723/html/index.html",
    },
    {
        id: 727,
        CompanyName: "辽宁星之火软件有限公司",
        Link: "https://assets.vrnewg.com/727/html/index.html",
    },
    {
        id: 729,
        CompanyName: "杭州蓝科光电科技有限公司",
        Link: "https://assets.vrnewg.com/729/html/index.html",
    },
    {
        id: 730,
        CompanyName: "广州南方测绘科技股份有限公司",
        Link: "https://assets.vrnewg.com/730/html/index.html",
    },
    {
        id: 731,
        CompanyName: "鹰图软件技术（青岛）有限公司（海克斯康数字智能）",
        Link: "https://assets.vrnewg.com/731/html/index.html",
    },
    {
        id: 734,
        CompanyName: "北京当康云科技有限公司",
        Link: "https://assets.vrnewg.com/734/html/index.html",
    },
    {
        id: 736,
        CompanyName: "北京星际导控科技有限责任公司",
        Link: "https://assets.vrnewg.com/736/html/index.html",
    },
    {
        id: 737,
        CompanyName: "上海瑾瑜科学仪器有限公司",
        Link: "https://assets.vrnewg.com/737/html/index.html",
    },
    {
        id: 738,
        CompanyName: "深圳慧闻智造技术有限公司",
        Link: "https://assets.vrnewg.com/738/html/index.html",
    },
    {
        id: 739,
        CompanyName: "山东华苑电缆有限公司",
        Link: "https://assets.vrnewg.com/739/html/index.html",
    },
    {
        id: 741,
        CompanyName: "威海至帆海洋装备科技有限公司",
        Link: "https://assets.vrnewg.com/741/html/index.html",
    },
    {
        id: 745,
        CompanyName: "上海中柔线缆有限公司",
        Link: "https://assets.vrnewg.com/745/html/index.html",
    },
    {
        id: 748,
        CompanyName: "广州鑫锐海船舶装备有限公司",
        Link: "https://assets.vrnewg.com/748/html/index.html",
    },
    {
        id: 749,
        CompanyName: "上海盈测科技有限公司",
        Link: "https://assets.vrnewg.com/749/html/index.html",
    },
    {
        id: 750,
        CompanyName: "深海智人（广州）技术有限公司",
        Link: "https://assets.vrnewg.com/750/html/index.html",
    },
    {
        id: 752,
        CompanyName: "青岛萨博海洋仪器科技有限公司",
        Link: "https://assets.vrnewg.com/752/html/index.html",
    },
    {
        id: 761,
        CompanyName: "深圳市微星物联科技有限公司",
        Link: "https://assets.vrnewg.com/761/html/index.html",
    },
    {
        id: 762,
        CompanyName: "深圳市潜鸟创新科技有限公司",
        Link: "https://assets.vrnewg.com/762/html/index.html",
    },
    {
        id: 763,
        CompanyName: "深圳北航新兴产业技术研究院",
        Link: "https://assets.vrnewg.com/763/html/index.html",
    },
    {
        id: 764,
        CompanyName: "西安天和防务技术股份有限公司",
        Link: "https://assets.vrnewg.com/764/html/index.html",
    },
    {
        id: 765,
        CompanyName: "深圳市魔方卫星科技有限公司",
        Link: "https://assets.vrnewg.com/765/html/index.html",
    },
    {
        id: 766,
        CompanyName: "中海北斗（深圳）导航技术有限公司",
        Link: "https://assets.vrnewg.com/766/html/index.html",
    },
    {
        id: 767,
        CompanyName: "北京鹏鹄物宇科技发展有限公司",
        Link: "https://assets.vrnewg.com/767/html/index.html",
    },
    {
        id: 768,
        CompanyName: "深圳市北斗海量科技有限公司",
        Link: "https://assets.vrnewg.com/768/html/index.html",
    },
    {
        id: 769,
        CompanyName: "深圳市天陆海导航设备技术有限责任公司",
        Link: "https://assets.vrnewg.com/769/html/index.html",
    },
    {
        id: 771,
        CompanyName: "自然资源部第二海洋研究所",
        Link: "https://assets.vrnewg.com/771/html/index.html",
    },
    {
        id: 776,
        CompanyName: "北京超图软件股份有限公司",
        Link: "https://assets.vrnewg.com/776/html/index.html",
    },
    {
        id: 780,
        CompanyName: "同方知网数字出版技术股份有限公司",
        Link: "https://assets.vrnewg.com/780/html/index.html",
    },
    {
        id: 781,
        CompanyName: "中山大学",
        Link: "https://assets.vrnewg.com/781/html/index.html",
    },
    {
        id: 786,
        CompanyName: "睿克盾（惠州）科技有限公司",
        Link: "https://assets.vrnewg.com/786/html/index.html",
    },
    {
        id: 835,
        CompanyName: "青岛瀛海信息技术有限公司",
        Link: "https://assets.vrnewg.com/835/html/index.html",
    },
    {
        id: 836,
        CompanyName: "青岛罗博飞海洋技术有限公司",
        Link: "https://assets.vrnewg.com/836/html/index.html",
    },
    {
        id: 837,
        CompanyName: "厦门唯恩电气有限公司",
        Link: "https://assets.vrnewg.com/837/html/index.html",
    },
    {
        id: 838,
        CompanyName: "深圳一诺新能科技有限公司",
        Link: "https://assets.vrnewg.com/838/html/index.html",
    },
    {
        id: 839,
        CompanyName: "深圳航天东方红卫星有限公司",
        Link: "https://assets.vrnewg.com/839/html/index.html",
    },
    {
        id: 844,
        CompanyName: "杭州智磁传感器有限公司",
        Link: "https://assets.vrnewg.com/844/html/index.html",
    },
    {
        id: 845,
        CompanyName: "深圳市朗非创新科技有限公司",
        Link: "https://assets.vrnewg.com/845/html/index.html",
    },
    {
        id: 846,
        CompanyName: "智船科技（北京）有限公司",
        Link: "https://assets.vrnewg.com/846/html/index.html",
    },
    {
        id: 848,
        CompanyName: "扬州市精诚电子有限公司",
        Link: "https://assets.vrnewg.com/848/html/index.html",
    },
    {
        id: 851,
        CompanyName: "南方海洋科学与工程广东省实验室（珠海）",
        Link: "https://assets.vrnewg.com/851/html/index.html",
    },
    {
        id: 853,
        CompanyName: "清华大学深圳国际研究生院",
        Link: "https://assets.vrnewg.com/853/html/index.html",
    },
    {
        id: 858,
        CompanyName: "深圳市华普智云科技有限公司",
        Link: "https://assets.vrnewg.com/858/html/index.html",
    },
    {
        id: 859,
        CompanyName: "广州盈泰铭丰科技有限公司",
        Link: "https://assets.vrnewg.com/859/html/index.html",
    },
    {
        id: 862,
        CompanyName: "陆海(深圳)科技有限公司",
        Link: "https://assets.vrnewg.com/862/html/index.html",
    },
    {
        id: 864,
        CompanyName: "长沙览声科技有限公司",
        Link: "https://assets.vrnewg.com/864/html/index.html",
    },
    {
        id: 865,
        CompanyName: "泰芯智能科技 (昆山)有限公司",
        Link: "https://assets.vrnewg.com/865/html/index.html",
    },
    {
        id: 868,
        CompanyName: "青岛诺泰克测量设备有限公司",
        Link: "https://assets.vrnewg.com/868/html/index.html",
    },
    {
        id: 871,
        CompanyName: "宁波扇贝科技有限公司",
        Link: "https://assets.vrnewg.com/871/html/index.html",
    },
    {
        id: 872,
        CompanyName: "航天宏图信息技术股份有限公司",
        Link: "https://assets.vrnewg.com/872/html/index.html",
    },
    {
        id: 873,
        CompanyName: "浙江大学海洋学院",
        Link: "https://assets.vrnewg.com/873/html/index.html",
    },
    {
        id: 874,
        CompanyName: "深圳市遨游通讯设备有限公司",
        Link: "https://assets.vrnewg.com/874/html/index.html",
    },
    {
        id: 876,
        CompanyName: "光流海洋（青岛）创新科技有限公司",
        Link: "https://assets.vrnewg.com/876/html/index.html",
    },
    {
        id: 877,
        CompanyName: "预海科技（北京）有限公司",
        Link: "https://assets.vrnewg.com/877/html/index.html",
    },
    {
        id: 878,
        CompanyName: "易站智联科技(广州)有限公司",
        Link: "https://assets.vrnewg.com/878/html/index.html",
    },
    {
        id: 879,
        CompanyName: "东莞小豚智能技术有限公司",
        Link: "https://assets.vrnewg.com/879/html/index.html",
    },
    {
        id: 882,
        CompanyName: "大洋智能（江苏）科技产业有限公司",
        Link: "https://assets.vrnewg.com/882/html/index.html",
    },
    {
        id: 885,
        CompanyName: "青岛海月辉科技有限公司",
        Link: "https://assets.vrnewg.com/885/html/index.html",
    },
    {
        id: 895,
        CompanyName: "广州智飞科技有限公司",
        Link: "https://assets.vrnewg.com/895/html/index.html",
    },
    {
        id: 898,
        CompanyName: "安徽中科谱锐达光电有限公司",
        Link: "https://assets.vrnewg.com/898/html/index.html",
    },
    {
        id: 903,
        CompanyName: "上海点鱼仪器有限公司",
        Link: "https://assets.vrnewg.com/903/html/index.html",
    },
    {
        id: 907,
        CompanyName: "中山市蓝藻生物食品开发有限公司北海分公司",
        Link: "https://assets.vrnewg.com/907/html/index.html",
    },
    {
        id: 908,
        CompanyName: "北海星天科技有限公司",
        Link: "https://assets.vrnewg.com/908/html/index.html",
    },
];
